import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faTh } from "@fortawesome/pro-duotone-svg-icons/faTh";
import { faRectangleWide } from "@fortawesome/pro-solid-svg-icons/faRectangleWide";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import LayoutSelect from "./LayoutSelect";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  control: {
    display: "flex",
    justifyContent: "space-between",

    lineHeight: "1",
    fontSize: "0.7em",

    [ScreenSizes.lgAndAbove]: {
      minWidth: "max-content",
      maxWidth: "11.375rem",
    },

    [ScreenSizes.xxlAndAbove]: {
      width: "100%",
    },
  },
};

const viewSelectStyles = {
  input: {
    padding: "0 0.313rem",
    minWidth: "max-content",
  },
  options: {
    minWidth: "max-content",
  },
  option: {
    minWidth: "max-content",
  },
  text: {},
  icon: {
    fontSize: "0.75rem",
    marginLeft: ".1rem",
  },
};

const getDefaultViews = (hasPro, entityType, isMobile, userIsInternal) => {
  const DEFAULT_VIEWS = [
    {
      id: "cards",
      title: "Card",
      value: "cards",
      icon: faRectangleWide,
      doubleIcon: true,
    },
    entityType === "podcast" && {
      id: "gallery",
      title: "Gallery",
      value: "gallery",
      icon: faTh,
    },
    entityType === "podcasts" && {
      id: "gallery",
      title: "Gallery",
      value: "gallery",
      icon: faTh,
    },
    (entityType === "podcast" || entityType === "episode" || hasPro) &&
      (!isMobile || userIsInternal) && {
        id: "list",
        title: "Table",
        value: "List",
        icon: faList,
      },
  ].filter((elem) => !!elem);

  return DEFAULT_VIEWS;
};

const ListViewControl = (props) => {
  const {
    handleLayoutView,
    layout,
    views: passedViews,
    analyticVariables,
    entityType,
    children,
  } = props;

  const hasPro = useUserHasPro();
  const userIsInternal = userHasInternalPermission();
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();

  const isMobile = isWindowSizeOrLess("large");
  const views = passedViews
    ? passedViews
    : getDefaultViews(hasPro, entityType, isMobile, userIsInternal);

  const handleClick = useCallback(
    (selectedLayout) => {
      sendGAEvent({
        action: "podcastViewLayoutButtonclick",
        selectedLayout,
        ...analyticVariables,
      });
      handleLayoutView(selectedLayout);
    },
    [analyticVariables, handleLayoutView]
  );

  return (
    <div data-id="view-layout" className={css(styles.control)}>
      <LayoutSelect
        layoutOptions={views}
        selected={layout}
        onChange={handleClick}
        ariaLabel="Choose View"
        widthAuto
        styles={props?.styles || viewSelectStyles}
        children={children}
      />
    </div>
  );
};

ListViewControl.propTypes = {
  layout: PropTypes.string,
  views: PropTypes.arrayOf(PropTypes.object),
  handleLayoutView: PropTypes.func,
  analyticVariables: PropTypes.object,
};

ListViewControl.defaultProps = {
  layout: null,
  handleLayoutView: null,
  analyticVariables: null,
};

export default memo(ListViewControl);
