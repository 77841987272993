import PropTypes from "prop-types";
import { useCallback } from "react";

import ColumnManagement from "components/Common/table/ColumnManagement";
import ColumnManagementToggleItem from "components/Common/table/ColumnManagementToggleItem";
import ListSearch from "components/Entities/ListControls/ListSearch";
import ListDateRangeSelect from "components/Lists/ListDateRangeSelect";
import ListSort from "components/Lists/ListSort";
import ListSortHeader from "components/Lists/ListSortHeader";
import ListViewControl from "components/Lists/ListViewControl";
import FiltersDisplayContainer from "containers/FiltersDisplayContainer";

import useSearchTerm from "hooks/useSearchTerm";
import { useStyles } from "hooks/useStyles";

import ListPageStyles from "styles/ListPageStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...ListPageStyles,
  contentWrapper: {
    maxWidth: "100%",
    [ScreenSizes.mdOnly]: {
      maxWidth: "85vw",
    },
  },
  desktopContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
  },
};

function ListPageHeaderSearch({
  listKey,
  searchPlaceholder,
  entity_type,
  eventContext,
}) {
  const { term, handleChange } = useSearchTerm({
    listKey,
    eventContext,
  });

  return (
    <ListSearch
      listKey={listKey}
      placeholder={searchPlaceholder || "Search this list..."}
      ariaLabel={`Search field for ${entity_type || "this"} list`}
      onChange={handleChange}
      value={term}
    />
  );
}

const ListPageHeader = (props) => {
  const {
    context,
    entity_type,
    filterHeading,
    fullHeading,
    handleLayoutView,
    heading,
    hideAbove,
    inlineWithHeader,
    isFilterable,
    isSearchable,
    isSortable,
    layout,
    listControls,
    titleAbove,
    titleAside,
    titleAfter,
    showViewControl,
    showHasGuest,
    renderCount,
    renderAboveTabs,
    showFilters,
    showHeading,
    searchPageHeader,
    passedViews,
    eventContext,
    sortEventTracking,
    showTableColumnManagement,
    tableInstance,
    listKey,
    listName,
    listSortItems,
    overrideSortStyles,
    overrideSortTitles = {},
    searchPlaceholder,
    showDateRangeSelect,
    tabButtons,
    type,
  } = props;

  const { styles, css } = useStyles(baseStyles, props);
  const removeFilterMarginLeft = isSearchable || showHasGuest;

  const listSearch = (
    <ListPageHeaderSearch
      listKey={listKey}
      searchPlaceholder={searchPlaceholder}
      entity_type={entity_type}
      eventContext={eventContext}
    />
  );

  const renderListViewControlChildren = useCallback(() => {
    if (showTableColumnManagement) {
      return ({ showColumnManagement, setShowColumnManagement }) => {
        return (
          showTableColumnManagement && (
            <ColumnManagement
              showColumnManagement={showColumnManagement}
              setShowColumnManagement={setShowColumnManagement}
              hideAllHandleClick={() => {
                tableInstance?.toggleAllColumnsVisible(false);
              }}
              showAllHandleClick={() => {
                tableInstance?.toggleAllColumnsVisible(true);
              }}
            >
              {tableInstance?.getAllColumns()?.map((column) => (
                <ColumnManagementToggleItem key={column?.id} column={column} />
              ))}
            </ColumnManagement>
          )
        );
      };
    }

    return null;
  }, [showTableColumnManagement, tableInstance]);

  return (
    <div className={css(styles.contentWrapper)}>
      {listName && !hideAbove && (
        <div
          data-id={`title-${listName.replace(/\s+/g, "-").toLowerCase()}`}
          className={css(styles.above, styles.sectionTitle)}
        >
          {listName}
        </div>
      )}
      {titleAbove}
      <div
        className={css(styles.header, renderAboveTabs && styles.overrideGap)}
      >
        {showHeading && (heading || fullHeading) && (
          <div
            className={css(
              styles.titleContainer,
              renderAboveTabs && styles.titleContainerMargin
            )}
          >
            {searchPageHeader ? (
              searchPageHeader
            ) : (
              <h1 className={css(styles.title)}>
                {fullHeading || (
                  <ListSortHeader
                    listKey={listKey}
                    overrideSortTitles={overrideSortTitles}
                    heading={heading}
                    filterHeading={filterHeading}
                    type={type || entity_type}
                    format={heading}
                  >
                    {inlineWithHeader}
                  </ListSortHeader>
                )}
                {fullHeading && inlineWithHeader}
              </h1>
            )}
            {titleAside}
          </div>
        )}
        {renderAboveTabs && renderAboveTabs()}
        {titleAfter}
        {showFilters && (
          <div
            className={css(
              styles.actionButtons,
              showViewControl && styles.fullWidth,
              tabButtons && styles.actionButtonsWithTabButtons
            )}
          >
            {tabButtons && (
              <div className={css(styles.actionButton)}>{tabButtons}</div>
            )}
            {isSortable && (
              <div className={css(styles.actionButton, styles.listSort)}>
                <ListSort
                  listKey={listKey}
                  include={listSortItems}
                  overrideSortTitles={overrideSortTitles}
                  styles={overrideSortStyles}
                  entity_type={entity_type}
                  sortEventTracking={sortEventTracking}
                />
              </div>
            )}
            {showDateRangeSelect && (
              <div className={css(styles.actionButton, styles.filterDateRange)}>
                <ListDateRangeSelect
                  listKey={listKey}
                  entity_type={entity_type}
                />
              </div>
            )}
            {showViewControl && (
              <div className={css(styles.actionButton)}>
                <ListViewControl
                  layout={layout}
                  handleLayoutView={handleLayoutView}
                  entityType={entity_type}
                  views={passedViews}
                  showTableColumnManagement={showTableColumnManagement}
                  styles={props?.styles}
                >
                  {renderListViewControlChildren()}
                </ListViewControl>
              </div>
            )}
            {isSearchable && (
              <div
                className={css(
                  styles.desktopContainer,
                  styles.actionButton,
                  styles.filterSearch
                )}
              >
                {listSearch}
              </div>
            )}
            {isFilterable && (
              <div
                className={css(
                  styles.actionButton,
                  styles.filterButton,
                  removeFilterMarginLeft && styles.noMarginLeft
                )}
              >
                <FiltersDisplayContainer
                  listKey={listKey}
                  showButton
                  showFilters={false}
                  context={context}
                />
              </div>
            )}
            {isSearchable && (
              <div
                className={css(
                  styles.mobileContainer,
                  styles.actionButton,
                  styles.filterSearch
                )}
              >
                {listSearch}
              </div>
            )}
            {listControls}
          </div>
        )}
        {renderCount && (
          <div className={css(styles.actionButton, styles.listCount)}>
            {renderCount()}
          </div>
        )}
      </div>
    </div>
  );
};

ListPageHeader.propTypes = {
  listName: PropTypes.string,
  listKey: PropTypes.string,
  listSortItems: PropTypes.array,
  handleLayoutView: PropTypes.func,
  layout: PropTypes.string,
  isSearchable: PropTypes.bool,
  showViewControl: PropTypes.bool,
  showHasGuest: PropTypes.bool,
  isSortable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  overrideSortTitles: PropTypes.object,
  overrideSortStyles: PropTypes.object,
  tabButtons: PropTypes.node,
  heading: PropTypes.string,
  fullHeading: PropTypes.string,
  filterHeading: PropTypes.string,
  showDateRangeSelect: PropTypes.bool,
  entity_type: PropTypes.string,
  type: PropTypes.string,
  hideAbove: PropTypes.bool,
  listControls: PropTypes.node,
  inlineWithHeader: PropTypes.node,
  titleAbove: PropTypes.object,
  titleAside: PropTypes.node,
  titleAfter: PropTypes.node,
  renderAboveTabs: PropTypes.func,
  showFilters: PropTypes.bool,
  showHeading: PropTypes.bool,
  context: PropTypes.string,
  passedViews: PropTypes.array,
  eventContext: PropTypes.object,
};

ListPageHeader.defaultProps = {
  isSearchable: true,
  isFilterable: true,
  isSortable: true,
  heading: null,
  fullHeading: null,
  filterHeading: null,
  tabButtons: null,
  overrideSortTitles: {},
  overrideSortStyles: {},
  listSortItems: undefined,
  podcastListViewItems: null,
  layout: null,
  listName: undefined,
  listKey: undefined,
  showDateRangeSelect: false,
  showViewControl: false,
  showHasGuest: false,
  entity_type: null,
  type: null,
  hideAbove: false,
  listControls: null,
  inlineWithHeader: null,
  titleAbove: null,
  titleAside: null,
  titleAfter: null,
  renderAboveTabs: null,
  showFilters: null,
  showHeading: null,
  passedViews: null,
};

export default ListPageHeader;
