import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { commonModuleGroup } from "modules/groups";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import StreamFeedProvider from "components/Analytics/StreamFeedProvider";
import UTMCatcher from "components/Analytics/UTMCatcher";
import HeightManager from "components/Common/HeightManager";
import ModalRoot from "components/Common/ModalRoot";
import NotificationsProvider from "components/Common/Notifications/NotificationsProvider";
import WindowResizeListener from "components/Common/WindowResizeListener";
import SideMenu from "components/Layout/SideMenu/Async";
import OnboardingTrigger from "components/OnboardingModal/OnboardingTrigger";
import AudioPlayerContainer from "components/Player/async";
import AudioPlayerProvider from "components/Player/AudioPlayerProvider";
import ScriptLoader from "components/ScriptLoader/ScriptLoader";
import FullStorySnippet from "components/Snippets/FullStorySnippet";
import UserWatcher from "components/Snippets/UserWatcher";
import TourManager from "components/Tours/TourManager";
import UseIsModeratorProvider from "components/UseIsModerator/UseIsModeratorProvider";
import HandleRedirect from "containers/HandleRedirect";
import TopLevelRoute from "pages/Common/TopLevelRoute";
import Error404 from "pages/Error404";
import RequestContext from "pages/RequestContext";

import AppActionHandler from "./AppActionHandler";
import AppDocumentHead from "./AppDocumentHead";
import AppRoutes from "./AppRoutes";
import UseJimoTracker from "./UseJimoTracker";
import UserIpTracker from "./UserIpTracker";

import { selectAppErrorPage } from "selectors/app";
import generateTransition from "utils/generateTransition";
import sendGAEvent from "utils/sendGAEvent";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowResizeListener from "hooks/useWindowResizeListener";
import { WindowScriptProvider } from "hooks/useWindowScripts";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import LayoutSizes from "styles/LayoutSizes";
import { secondaryVisibleStyles } from "styles/LayoutStyles";

const baseStyles = {
  sidebarContainer: {
    width: "0",
    height: "100%",
    position: "absolute",
    zIndex: 15,
    [LayoutSizes.hasTabletMenu]: {
      width: "40%",
      right: "100%",
    },

    [LayoutSizes.hasMobileMenu]: {
      width: "80%",
      right: "100%",
    },
  },
  anchor: { color: "#882", textDecoration: "underline" },
  sectionsWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    minHeight: 0,
    minWidth: 0,
    flex: "none",

    [LayoutSizes.hasMobileOrTabletMenu]: {
      transition: generateTransition({
        targets: ["transform"],
        speed: "150ms",
      }),
      overflow: "visible",
    },
  },
  outer: {},
  base: {
    width: "100%",
    background: "#fff",
  },
  hidden: {
    display: "none",
  },
  exit: {
    position: "fixed",
    zIndex: "2147483003",
    padding: "0 ",
    margin: "0 ",
    bottom: "0",
    cursor: "pointer",
    boxShadow:
      "0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16)",
    boxSizing: "content-box",
    width: "63px",
    height: "59px",
    border: "none",
    background: "rgb(255, 255, 255)",
    fontSize: "1.25rem",
    borderRadius: "0",
    [LayoutSizes.hasTabletMenu]: {
      left: "calc(100vw - 60vw)",
    },
    [LayoutSizes.hasMobileMenu]: {
      left: "calc(100vw - 20vw)",
    },
  },
  overlay: {
    background: colours.white,
    opacity: "0.85",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
};

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = (props) => {
  const { nonce } = props;

  const appError = useReduxState((state) => state.app.get("appError"), []);
  const errorPage = useReduxState((state) => selectAppErrorPage(state), []);

  const requestContext = useContext(RequestContext);

  const { isWindowSizeOrMore, isWindowSizeOrLess } = useWindowSize();

  const { onResize } = useWindowResizeListener();

  const [menuOpen, setMenuOpen] = useState(false);

  const { styles } = useStyles([
    baseStyles,
    menuOpen && secondaryVisibleStyles,
  ]);

  const closeMenu = useCallback(() => {
    sendGAEvent({
      action: "sideMenuClose",
    });
    setMenuOpen(false);
  }, []);

  const handleMenuToggleClick = useCallback(() => {
    sendGAEvent({
      action: `sideMenu${menuOpen ? "Close" : "Open"}`,
    });
    setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const renderRoutes = useCallback(
    (override) => (
      <div className={css(styles.base)} id="app-base">
        {override || <AppRoutes onMenuToggleClick={handleMenuToggleClick} />}
        <ModalRoot />
      </div>
    ),
    [handleMenuToggleClick, styles.base]
  );

  useEffect(() => {
    if (menuOpen && isWindowSizeOrMore("large")) {
      closeMenu();
    }
  });

  return (
    <div className={css(styles.outer)}>
      <WindowScriptProvider>
        <AppDocumentHead />
        <ScrollToTop />
        <WindowResizeListener onResize={onResize} />
        <TourManager />
        <OnboardingTrigger />
        <NotificationsProvider timeout={5000}>
          <HeightManager>
            <StreamFeedProvider>
              <AudioPlayerProvider>
                <UseIsModeratorProvider>
                  <div className={css(styles.sectionsWrapper)}>
                    {isWindowSizeOrLess("medium") && (
                      <div className={css(styles.sidebarContainer)}>
                        <SideMenu
                          {...props}
                          isOpen={menuOpen}
                          onToggleOpen={handleMenuToggleClick}
                        />
                      </div>
                    )}
                    {!errorPage && !appError && renderRoutes()}
                    {errorPage === "network_error" && <div>NETWORK ERROR</div>}
                    {appError &&
                      renderRoutes(
                        <TopLevelRoute
                          transparent={false}
                          modules={[...commonModuleGroup]}
                        >
                          <Error404 />
                        </TopLevelRoute>
                      )}
                    <HandleRedirect />
                    {menuOpen && (
                      <div
                        onClick={closeMenu}
                        className={css(styles.overlay)}
                      ></div>
                    )}
                  </div>
                  {menuOpen && (
                    <button onClick={closeMenu} className={css(styles.exit)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  )}
                  {!requestContext.server && <AudioPlayerContainer />}
                </UseIsModeratorProvider>
              </AudioPlayerProvider>
            </StreamFeedProvider>
          </HeightManager>
        </NotificationsProvider>
        <UTMCatcher />
        <FullStorySnippet />
        <ScriptLoader nonce={nonce} />
        <UserWatcher />
        <AppActionHandler />
        <UseJimoTracker />
        <UserIpTracker />
      </WindowScriptProvider>
    </div>
  );
};

App.propTypes = {
  nonce: PropTypes.string,
};

App.defaultProps = {
  nonce: null,
};

export default App;
