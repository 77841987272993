import PropTypes from "prop-types";
import { useCallback } from "react";

import FiltersDisplay from "components/Lists/FiltersDisplay";

import modalActions from "actions/modals";
import listActions from "actions/pagination";
import { categories } from "constants/ga";
import { selectAllFilters, selectListEntityType } from "selectors/pagination";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useExecOnMount from "hooks/useExecOnMount";
import useList from "hooks/useList";
import useReduxState from "hooks/useReduxState";

const FiltersDisplayContainer = (props) => {
  const { listKey, showFilters, context } = props;

  const { sort, entity_type } = useList(listKey);

  const allFilters = useReduxState(
    (state) => selectAllFilters(state, selectListEntityType(state, listKey)),
    [listKey]
  );

  const { filters, availableFilters } = useList(listKey);

  const { getFilters, removeFilter, showModal } = useActionCreators({
    showModal: modalActions.showModal,
    getFilters: listActions.getFilters,
    removeFilter: listActions.removeFilter,
  });

  const execMount = useCallback(
    () => getFilters(listKey),
    [getFilters, listKey]
  );

  useExecOnMount(execMount, showFilters && filters.size > 0);

  const handleOpenFilters = useCallback(() => {
    showModal("filters", { listKey, context, entityType: entity_type });
    sendGAEvent({
      category: categories.USER_ACTION,
      action: "filtersDisplayModalOpened",
      componentContext: "FiltersDisplayContainer",
      context: "filters",
      listKey,
      sort,
      entity_type,
    });
  }, [showModal, listKey, sort, entity_type, context]);

  const handleRemoveFilter = useCallback(
    (filter) => {
      removeFilter(listKey, filter);
      sendGAEvent({
        category: categories.USER_ACTION,
        action: "filterRemoved",
        componentContext: "FiltersDisplay",
        context: "filters",
        filter,
        listKey,
        sort,
        entity_type,
      });
    },
    [listKey, removeFilter, sort, entity_type]
  );

  return (
    <FiltersDisplay
      {...props}
      filters={filters}
      availableFilters={availableFilters}
      allFilters={allFilters}
      onAddFiltersClick={handleOpenFilters}
      onRemoveFilter={handleRemoveFilter}
    />
  );
};

FiltersDisplayContainer.propTypes = {
  listKey: PropTypes.string,
  showFilters: PropTypes.bool,
};
FiltersDisplayContainer.defaultProps = {
  listKey: null,
  showFilters: false,
};

export default FiltersDisplayContainer;
