import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Downshift from "downshift";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo, useState } from "react";

import { baseStyles as selectStyles } from "./SelectStyles";

import { indexKey } from "utils/misc";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";

const baseStyles = {
  ...selectStyles,
  content: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.7em 1rem",
  },
  icon: {
    fontSize: "0.875rem",
    display: "block",
    maxWidth: "1.25em",
    textAlign: "center",
    flex: "0 0 auto",
    marginRight: "0.5rem",
  },
  iconCopy: {
    marginTop: "-0.188rem",
  },
  text: {
    flex: "1 1 auto",
  },
  sideButtons: {
    ...selectStyles.sideButtons,
  },
  sideButton: {
    display: "grid",
    placeItems: "Center",
    width: "3em",
    textAlign: "center",
    border: "none",
    borderLeft: `1px solid ${colours.borderGrey}`,

    ":hover": {
      cursor: "pointer",
    },
  },
  doubleIcon: {
    display: "flex",
    flexDirection: "column",
  },
  option: {
    ...selectStyles.option,
    minWidth: "3.313rem",
  },
  maxContent: {
    width: "max-content",
  },
  container: {
    display: "flex",
    gap: 3,
    alignItems: "center",
  },
  settingsOpen: {
    background: colours.rowSelected,
    color: colours.checkboxSelected,
    border: `1px solid ${colours.rowSelected}`,
  },
  settingsIconOpen: {
    color: colours.checkboxSelected,
  },
};

function LayoutSelect(props) {
  const { layoutOptions, selected, onChange, ariaLabel, children } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const hasPro = useUserHasPro();

  const [showColumnManagement, setShowColumnManagement] = useState(false);

  const handleClick = useCallback(
    () => setShowColumnManagement(!showColumnManagement),
    [showColumnManagement]
  );

  const selectedLayout = useMemo(
    () =>
      layoutOptions &&
      layoutOptions.find((s) => {
        return s !== null && s.id === selected;
      }),
    [selected, layoutOptions]
  );

  const handleChange = useCallback(
    (newLayout) => {
      sendGAEvent({
        action: "podcastLayoutChanged",
        newLayout: newLayout.id,
        context: "PodcastView",
      });
      onChange(newLayout.id);
    },
    [onChange]
  );

  const toggleSettingManagement = useCallback(
    (closeMenu) => (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (closeMenu) {
        closeMenu();
      }

      handleClick();

      return false;
    },
    [handleClick]
  );

  const ListOptions = ({ selectedItem, highlightedIndex, getItemProps }) => {
    return (
      <div data-testid="options" className={css(styles.options)}>
        {layoutOptions.map((option, i) => {
          const selected = selectedItem && option.id === selectedItem.id;
          const highlighted = highlightedIndex === i;

          return (
            <div
              {...getItemProps({ item: option })}
              data-id="layout-option"
              className={css(
                styles.option,
                highlighted && styles.highlightedOption,
                selected && styles.selectedOption,
                selected && highlighted && styles.selectedHighlightedOption,
                styles.content
              )}
              key={indexKey(`layoutSelect${i}`)}
            >
              {option.icon && !option.hideDropdownIcon && (
                <span className={css(option.doubleIcon && styles.doubleIcon)}>
                  <FontAwesomeIcon
                    dataid={option?.id}
                    icon={option.icon}
                    className={css(styles.icon)}
                    fixedWidth
                  />
                  {option.doubleIcon && (
                    <FontAwesomeIcon
                      dataid={option?.id}
                      icon={option.icon}
                      className={css(styles.icon, styles.iconCopy)}
                      fixedWidth
                    />
                  )}
                </span>
              )}
              <span
                data-id={`layout-option-${option.title.toLowerCase()}`}
                className={css(styles.text)}
              >
                {option.title}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Downshift
      selectedItem={selectedLayout}
      onChange={handleChange}
      itemToString={(layout) => layout && layout.title}
    >
      {({
        getToggleButtonProps,
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        innerRef,
        closeMenu,
      }) => (
        <h2
          data-id="layout-select"
          className={css(styles.outer)}
          ref={innerRef}
          aria-label={ariaLabel}
        >
          <button
            className={css(styles.input, styles.maxContent)}
            {...(getToggleButtonProps && getToggleButtonProps())}
            aria-label={ariaLabel}
          >
            {selectedItem ? (
              <span
                data-id="selected-layout-option"
                className={css(styles.content)}
              >
                {selectedItem.icon && (
                  <span className={css(styles.container)}>
                    <span
                      className={css(
                        selectedItem.doubleIcon && styles.doubleIcon
                      )}
                    >
                      <FontAwesomeIcon
                        dataid={selectedItem?.id}
                        icon={selectedItem.icon}
                        fixedWidth
                        className={css(styles.icon)}
                      />
                      {selectedItem.doubleIcon && (
                        <FontAwesomeIcon
                          dataid={selectedItem?.id}
                          icon={selectedItem.icon}
                          fixedWidth
                          className={css(styles.icon, styles.iconCopy)}
                        />
                      )}
                    </span>
                    <span
                      data-id={`layout-option-${selectedItem.title.toLowerCase()}`}
                      className={css(styles.text)}
                    >
                      {selectedItem.title}
                    </span>
                  </span>
                )}
              </span>
            ) : (
              <span className={css(styles.content, styles.text)}>
                Choose Layout
              </span>
            )}
            <div className={css(styles.sideButtons)}>
              {selectedItem?.id === "list" && hasPro && children && (
                <div
                  data-id="sort-select-direction"
                  onClick={toggleSettingManagement(closeMenu)}
                  onKeyDown={(e) =>
                    eventIsFieldTrigger(e) &&
                    toggleSettingManagement(closeMenu)(e)
                  }
                  className={css(
                    styles.sideButton,
                    showColumnManagement && styles.settingsOpen
                  )}
                  aria-label="Sort Direction"
                >
                  <FontAwesomeIcon
                    dataid="tableSettings"
                    icon={faCog}
                    className={css(
                      showColumnManagement
                        ? styles.settingsIconOpen
                        : styles.sideIcon
                    )}
                  />
                </div>
              )}
              <span className={css(styles.sideButton)}>
                <FontAwesomeIcon
                  dataid="dropDown"
                  icon={faChevronDown}
                  className={css(
                    isOpen ? styles.openToggleIcon : styles.toggleIcon
                  )}
                />
              </span>
            </div>
          </button>
          {children &&
            children({
              showColumnManagement,
              setShowColumnManagement,
            })}
          {isOpen && (
            <ListOptions
              selectedItem={selectedItem}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
            />
          )}
        </h2>
      )}
    </Downshift>
  );
}

LayoutSelect.propTypes = {
  layoutOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  direction: PropTypes.string,
  dataId: PropTypes.string,
  ariaLabel: PropTypes.string,
  styles: PropTypes.object,
  iconOnly: PropTypes.bool,
};

LayoutSelect.defaultProps = {
  dataId: null,
  selected: undefined,
  direction: "desc",
  ariaLabel: undefined,
  styles: null,
  children: null,
  iconOnly: false,
};

export default memo(LayoutSelect);
